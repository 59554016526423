<template>
  <div class="flex-shrink-0 w-full z-0 relative flex overflow-hidden pt-0 lg:pt-24" style="min-height:700px; background: url('/img/parrots/clouds.png') center center repeat">
    <img src="/img/parrots/homepage/parrot-flying.png" alt="parrot" class="absolute left-20 bottom-14 lg:bottom-0 lg:left-24 xl:bottom-14 2xl:bottom-auto xl:left-20 2xl:left-36 w-3/5 md:w-2/5 xl:w-1/3 2xl:w-3/12">
    <div class="relative max-w-screen-sm mx-auto text-center p-6 z-10">
      <h2 class="dk-brown my-6">once upon a time…</h2>
      <p class="dk-brown MaaxMedium">Looking to hear how the Parrots came about? Read the Barefoot Parrots Lore here.</p>
      <button class="bg-black bg-opacity-20 rounded-full mt-4" style="border: 2px solid #fff"><a href="/the-lore" class="text-base lg:text-xl block px-8 py-2 text-white hover:text-white MaaxBold">READ MORE</a></button>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<template>
	<transition name="fade" mode="out-in">
		<slot
			v-if="! state.isConnected"
			name="default"
			:walletAvailable="state.walletAvailable"
			:buttonEvents="buttonEvents"
			:isConnecting="state.connecting"
		/>
		<slot v-else name="connected" />
	</transition>
</template>
<script>
import { onMounted, reactive } from 'vue';
import useMetamask from '../modules/metamask.js';
const { init: initMetamask, connectWallet, connected: isConnected, on, currentAccount } = useMetamask();

export default {
	emits: ['connected'],
	setup(prop, { emit: $emit }) {
		onMounted(() => {
			if (window.ethereum !== undefined) {
				attachEvents();
				initMetamask(window.ethereum);
			}
		});

		const state = reactive({
			isConnected,
			connecting: false,
			walletAvailable: window.ethereum !== undefined,
		})

		const attachEvents = () => {
			on('connected', (wallet) => {
				state.connecting = false;
				// state.isConnected = true;
				$emit('connected', wallet)
			});
		}

		return {
			state,
			buttonEvents: {
				click: async() => {
					state.connecting = true;
					await connectWallet();
					state.connecting = false;
				}
			}
		}
	}
}
</script>
<style scoped>
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-out;
}
</style>

import axios from 'axios';
let apiKey;
let client;

export default function useOpensea() {
    const init = (key) => {
        apiKey = key;

        client = buildClient(key);
    };

    const buildClient = (key) => {
        return axios.create({
            baseUrl: apiEndpoint(),
            timeout: 2000,
            headers: { 'X-API-KEY': key },
        });
    };

    const assets = (address, contracts) => {
        return new Promise(async (resolve, reject) => {
            let assets = [];

            do {
                const contractChunk = contracts.splice(0, 25);
                let queryString = buildQueryString({
                    owner: address,
                    asset_contract_addresses: contractChunk,
                    limit: 50,
                });

                let offset = 0;
                do {
                    let thisQueryString = `${queryString}&offset=${offset}`;
                    let response = await axios.get(
                        apiEndpoint('/api/v1/assets') + `?${thisQueryString}`,
                        {
                            headers: {
                                'X-API-KEY': apiKey,
                            },
                        }
                    );

                    if (
                        response.status == 200 &&
                        response.data.assets &&
                        response.data.assets.length > 0
                    ) {
                        assets.push(...response.data.assets);
                    } else {
                        break;
                    }

                    offset += 50;
                } while (true);
            } while (contracts.length > 0);

            resolve(assets);
        });
    };

    const getClient = () => {
        return client;
    };

    const apiEndpoint = (uri) => {
        return `https://api.opensea.io${uri}`;
    };

    const buildQueryString = (params) => {
        let parts = [];
        for (const key in params) {
            if (Array.isArray(params[key])) {
                for (const val of params[key]) {
                    parts.push([key, val]);
                }
            } else {
                parts.push([key, params[key]]);
            }
        }

        let qs = [];
        for (const part of parts) {
            qs.push(part.join('='));
        }

        return qs.join('&');
    };

    return {
        init,
        assets,
        getClient,
    };
}

<template>
  <div class="flex-shrink-0 w-full overflow-hidden flex-shrink-0 bg-[url('/img/parrots/bg-texture.webp')] bg-[#40200A]/100 bg-blend-color-burn">
    <div class="relative max-w-screen-md mx-auto text-center p-6">

		<slot name="default">
			<h2 class="text-white my-20">Connect With Us</h2>
		</slot>
      <div class="lg:grid grid-cols-3 space-y-14 lg:space-y-0">
        <div>
          <a href="/discord" target="_blank">
            <img src="/img/parrots/logo-d.svg" alt="Discord" class="mx-auto w-24 lg:w-32">
            <button class="text-md block px-8 py-2 text-white MaaxBold bg-none rounded-full mt-4 border-2 border-white mx-auto uppercase">Join our Discord</button>
          </a>
        </div>
        <div>
          <a href="/opensea" target="_blank" class="hover:text-white hover:bg-neon-300">
            <img src="/img/parrots/logo-os.svg" alt="OpenSea" class="mx-auto w-24 lg:w-32">
            <button class="text-md block px-8 py-2 text-white MaaxBold bg-none rounded-full mt-4 border-2 border-white mx-auto uppercase">Buy on OpenSea</button>
          </a>
        </div>
        <div>
          <a href="/looksrare" target="_blank" class="hover:text-white">
            <img src="/img/parrots/logo-lr.svg" alt="Looks Rare" class="mx-auto w-24 lg:w-32">
            <button class="text-md block px-8 py-2 text-white MaaxBold bg-none rounded-full mt-4 border-2 border-white mx-auto uppercase">Buy on LooksRare</button>
          </a>
        </div>
      </div>
      <img src="/img/parrots/footer.png" alt="parrot" class="mx-auto py-20 w-1/2 lg:w-2/6">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    currentYear() {
      const current = new Date();
      const date = `${current.getFullYear()}`;
      return date;
    }
  }
}
</script>

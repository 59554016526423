<template>
	<div>
		<div class="">
			<p class="text-3xl m-0 p-0">
				{{ `${state.currentAccount.substr(0, 6)}...${state.currentAccount.substr(-6)}` }}
			</p>
			<p class="text-xl font-bold m-0 p-0 text-brown-900">Connected wallet</p>
		</div>

		<div v-if="state.migrationActive">
			<div class="my-4 space-y-4 xs:space-y-0 xs:grid grid-cols-2 gap-4 items-stretch">
				<div class="border-brown-900 border-2 rounded py-4">
					<p class="text-3xl m-0 p-0">{{ mintCount? mintCount.dao : 0 }}</p>
					<p class="text-xl font-bold m-0 p-0">Pandemonium</p>
				</div>
				<div class="border-brown-900 border-2 rounded py-4">
					<p class="text-3xl m-0 p-0">{{ mintCount? mintCount.event : 0 }}</p>
					<p class="text-xl font-bold m-0 p-0">Barefoot Passes</p>
				</div>
			</div>
			<p v-if=" ! mintCount" class="pt-2">
				Your wallet is not eligible to mint any Barefoot Pandemonium or Barefoot Pass tokens.
				<br>
				Check your hidden tab on OpenSea if you believe you should have tokens to mint. They may
				have already been airdropped to you.
			</p>
		</div>
		<div>
			<p class="pt-2">
				The Token Migration Window has Closed.
			</p>
		</div>
	</div>

	<div v-if="mintCount">
		<button type="button"
				class="
				text-brown-900
				Nove
				text-lg
				sm:text-2xl
				block
				w-full
				px-8
				py-4
				rounded-full
				border-brown-800
				border-2
				bg-brown-400
				bg-opacity-50
				uppercase
				cursor-pointer
				hover:cursor-pointer
				disabled:cursor-not-allowed
				disabled:text-gray-400
				disabled:border-gray-400
				disabled:bg-gray-200
				disabled:bg-opacity-100"
				:disabled="state.hasMinted"
				@click="mint">
			Mint Now
		</button>
	</div>

	<Modal
		v-if=" !! state.showModal"
		:modalComponent="state.showModal"
		:data="modalData"
		@closeModal="hideModal"
	/>
</template>
<script>
import { computed, markRaw, onMounted, onUnmounted, reactive, ref, unref } from 'vue';
import legend from '../../assets/json/merkle-legend.json';
import tree from '../../assets/json/merkle-tree.json';
import ParrotMigration from '../../modules/ParrotMigration.js';

import Modal from '../Modal.vue';
import ProcessingModal from './modals/ProcessingModal.vue';
import MintedModal from './modals/MintedModal.vue';

import MintProxy from '../../abi/MintProxyABI.js';
import useMetamask from '../../modules/metamask.js';
const MINT_PROXY_ADDRESS = import.meta.env.VITE_MIGRATION_MINT_PROXY;


const { init, currentAccount, sign, loadContract } = useMetamask();

export default {
	components: { Modal },
	setup() {

		const state = reactive({
			migrationActive: false,
			currentAccount,
			hasMinted: false,

			showModal: null,
			transactionHash: null,
			receipt: null,
		});

		const modalData = ref(null);

		const processingModalData = computed(() => {
			return {
				transactionHash: state.transactionHash,
			}
		});

		let contract;
		let Merkle = new ParrotMigration();
		let watchInterval;
		Merkle.buildTree(tree);

		onMounted(async() => {
			if (undefined !== window.ethereum) {
				init(window.ethereum);
				contract = await loadContract(MintProxy, MINT_PROXY_ADDRESS);

				watchInterval = setInterval(checkMintStatus, 3000);
			}
		});

		onUnmounted(() => {
			clearInterval(watchInterval);
		});

		const mintCount = computed(() => {
			return 0;
			if ( undefined !== legend[ state.currentAccount ]) {
				return legend[ state.currentAccount ];
			}

			return null;
		});

		const checkMintStatus = async() => {
			if (! mintCount.value) {
				return false;
			}

			if (state.currentAccount) {
				const leaf = mintCount.value.hash || null;

				if ( ! leaf) {
					return false;
				}

				try {
					state.hasMinted = await contract.methods.minted(leaf).call({
						from: state.currentAccount,
						gas: 50_000,
					});

					clearInterval(watchInterval);
				} catch (err) {
					console.error("Couldn't get current mint status", err);
					state.hasMinted = false;
				}
			} else {
				return false;
			}
		}

		const mint = async(e) => {
			// if (state.hasMinted) {
			// 	state.transactionHash = '0x91b96bc15435371464231890aef09781211cd6fe6a683792c54de514f800c65c';
			// 	showProcessingModal();
			// }

			// const proof = Merkle.getProof( mintCount.value.hash );
			const proof = [];
			const tx = contract.methods.mintTokens(0, 0, proof); //mintCount.value.dao, mintCount.value.event, proof);
			let gasEstimate;
			try {
				gasEstimate = await tx.estimateGas({
					from: state.currentAccount,
					gas: 10_000_000,
				});

				await tx.send({
					from: state.currentAccount,
					gas: Math.ceil(gasEstimate * 1.2),
				}).on('transactionHash', (hash) => {
					state.transactionHash = hash;
					showProcessingModal();
				}).on('receipt', (receipt) => {
					state.receipt = receipt;
					showMintedModal();
				});
			} catch (e) {
				console.error('Gas estimate failed', e);
			}
		}

		function hideModal() {
			state.showModal = null;
			modalData.value = {};
		}

		function showProcessingModal() {
			showModal(ProcessingModal, unref(processingModalData))
		}

		async function showMintedModal(tokens) {
			showModal(MintedModal, {
				receipt: state.receipt,
			});
		}

		function showModal(modal, data) {
			state.showModal = markRaw(modal);
			modalData.value = data;
		}

		return {
			state,
			mintCount,
			mint,
			modalData,
			hideModal,
		}
	}
}
</script>

<template>
	<div class="bg-brown-900 text-brown-400 rounded-xl">
		<div class="w-3/4 mx-auto">
			<h3 class="text-brown-400">
				Awaiting Transaction Confirmation
			</h3>
		</div>


		<div class="mt-4 text-xl">
			<img src="/img/parrots/parrot-logo-round.png" class="w-1/2 mx-auto my-8 ">

			<div v-if="props.data.transactionHash">
				<span class="font-semibold text-brown-400">Transaction Hash:</span>

				<a :href="`https://etherscan.io/tx/${props.data.transactionHash}`"
					class="text-lg inline-block mx-4" target="_blank">
					{{ props.data.transactionHash.substr(0, 6) }}...{{ props.data.transactionHash.substr(-6) }}
				</a>
			</div>
		</div>
	</div>
</template>
<script>
import { computed } from '@vue/runtime-core';
export default {
	props: ['data', 'close'],
	setup(props) {

		return {
			props,
		};
	}
}
</script>

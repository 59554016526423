import { MerkleTree } from 'merkletreejs';
import web3 from '~web3/dist/web3.min.js';

export default class MerkleWrapper {
  constructor() {
    this.authList = [];
  }

  addLeaf(leaf) {
    this.authList.push(leaf);
  }

  resetLeaves() {
    this.authList = [];
  }

  // if leafList is omitted, use the leaves we have stored
  buildTree(leafList) {
    // overwrite the internally stored authList
    if (leafList !== undefined) {
      this.authList = leafList;
    }

    this.tree = new MerkleTree(this.authList, web3.utils.keccak256, { sort: true });
  }

  getRoot() {
    if (this.tree === undefined) throw Error('buildTree() must be run before the root can be generated');

    return this.tree.getHexRoot();
  }

  getProof(leaf) {
    if (this.tree === undefined) throw Error('buildTree() must be run before the root can be generated');

    return this.tree.getHexProof(leaf);
  }

  // returns array that can be used to initialize a new MerkleTree object
  // this return value can be passed to this.buildTree
  getLeaves() {
    if (this.tree === undefined) throw Error('buildTree() must be run before the root can be generated');

    return this.tree.getHexLeaves();
  }

  // whole tree for debugging
  getTree() {
    this.tree.getHexLayers();
  }
}

<template>
	<GeneralHeader>
		<div class="relative">
			<img src="/img/parrots/homepage/parrot-open-wings.png" alt="parrot"
				class="hidden lg:block absolute -top-32 -left-32 w-36">
			<h1 class="text-brown-800 Nove">
				Parrot Mint &amp; Reveal is Now Live
			</h1>
			<p>
				<a href="#mint"
					class="inline-block text-xl MaaxBold uppercase dk-brown bg-white/80 rounded-full px-8 py-2 my-6 border-2 border-brown-800">
					Join the Pandemonium
				</a>
			</p>
		</div>
	</GeneralHeader>

	<div class="max-w-2xl xl:max-w-5xl mx-auto px-6 md:px-0 ">
		<div class="text-center">
			<div class="py-16" id="mint">
				<WalletConnected>
					<template #default="{ buttonEvents: { click }, walletAvailable }">
						<div>
							<div class="bg-brown-900/90 text-brown-400 p-8 rounded-lg max-w-screen-md mx-auto text-left text-lg my-6">
								<p class="text-2xl text-brown-400 2xl:text-3xl">Use your Barefoot Parrot mint passes now!</p>

								<p class="text-2xl text-brown-400 2xl:text-3xl">For just .08 ETH you can fully convert your Mint Pass(es) to a Barefoot Parrot.</p>

								<p class="text-2xl text-brown-400 2xl:text-3xl">Once the open mint is live, the price will start at .08 ETH per parrot and increases as mint passes are used.</p>

								<p class="text-2xl text-brown-400 2xl:text-3xl">However, everyone that does this pre-mint phase is locked in for just .03 ETH each.</p>

								<p class="text-2xl text-brown-400 2xl:text-3xl">This is your chance at the absolute best pricing.</p>
								<p class="text-2xl text-brown-400 2xl:text-3xl">Maybe you will score the rarest parrot in the flock.</p>

								<p class="text-2xl text-brown-400 2xl:text-3xl">Connect your wallet below to get started!</p>
							</div>

							<div class="md:pt-10">
								<button
										v-if="walletAvailable"
										type="button"
										class="
										Nove
										text-lg
										sm:text-2xl
										md:text-5xl
										block
										w-full
										px-8
										lg:px-16
										py-6
										lg:py-12
										rounded-full
										border-brown-800
										border-2
										bg-brown-400
										bg-opacity-50
										uppercase"
										@click="click"
									>
									<!-- <span class="block font-semibold leading-none">Pay with ETH <i class="fab fa-ethereum"></i> </span> -->
									<span class="text-brown-800 block leading-none">Connect your Wallet</span>
								</button>

								<div v-else>
									<p class="text-brown-800 block leading-none uppercase Nove text-2xl my-0">
										Please use a web3 enabled browser
									</p>
									<p class="text-brown-800 my-0">
										We were unable to detect your web3 wallet in the current browser.
									</p>
								</div>
							</div>
						</div>
					</template>
					<template #connected>
						<MintPass />
					</template>
				</WalletConnected>
			</div>
		</div>
	</div>

	<GeneralFooter />
</template>
<script>
import { onMounted, reactive } from 'vue';
import useMetamask from '../modules/metamask.js';

import useOpensea from '../modules/opensea.js';
import loadUserAssets from '../modules/user-assets.js';

const { init: userInit, loadUser, tokensForProject } = loadUserAssets();
const { init: initMetamask, connected, isConnecting, connectWallet, currentAccount, loadContract, on, sign, requestChain } = useMetamask();
const { getClient } = useOpensea();

const CHAIN_ID = import.meta.env.VITE_NETWORK_ID;
const MODE = import.meta.env.VITE_MODE;
const CHECK_WALLET = import.meta.env.VITE_CHECK_WALLET;

import GeneralHeader from '../components/parrots/GeneralHeader.vue';
import GeneralFooter from '../components/parrots/GeneralFooter.vue';
import WalletConnected from '../components/WalletConnected.vue';
import MintPass from '../components/mint/MintPass.vue';

export default {
	components: { GeneralHeader, GeneralFooter, WalletConnected, MintPass },
	emits: ['next'],
	setup(props, { emit: $emit }) {
		const state = reactive({
			error: null,
			isConnected: connected,
			isConnecting,
			chainID: null,
		});

		onMounted(() => {
			attachEvents();

			try {
				initMetamask(window.ethereum);
			} catch (err) {
			}
		});

		const attachEvents = () => {
			on('connected', onConnected);

			on('chainChanged', async() => {
			});
		}

		const onConnected = async(connection) => {
            console.log('connected');
			state.chainID = connection.chainID;
			// loadUser(getUserWallet(connection.currentAccount));

			const onChain = await verifyChain(CHAIN_ID);
			if ( ! onChain) {
                alert('Please select the Ethereum Mainnet RPC in your wallet');
			    return;
			}

			$emit('next')
		}

        const getUserWallet = (wallet) => {
            if (MODE == 'test') {
                return CHECK_WALLET;
            }

            return wallet;
        }

		const verifyChain = async(chainID) => {
			const result = await requestChain(chainID);

			if ( ! result) {
				displayError("Your wallet must be set to the ethereum mainnet to continue");
			}

			return result;
		}

		const clearError = () => {
			state.error = null;
		}

		const displayError = (error) => {
			state.error = error;
		}

        const next = () => {
            $emit('next');
        }

		return {
			state,
			connectWallet,
            next,
		}
	}
}
</script>

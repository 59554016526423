<template>
  <div class="flex-shrink-0 w-full z-0 relative flex bg-[url('/img/parrots/bg-texture.webp')] bg-[#936837]/70 bg-blend-color-burn">
    <div class="w-full h-full hidden xl:block justify-self-stretch absolute z-0" style="background: url('/img/parrots/homepage/tri-border-left.png') 5% 50% no-repeat, url('/img/parrots/homepage/tri-border-right.png') 95% 50% no-repeat;"></div>
    <div class="relative max-w-screen-lg mx-auto text-center p-6 z-10">
      <img src="/img/parrots/homepage/carved-parrots.png" alt="carved parrots" class="lg:px-10 my-6 mx-auto -mt-24">
      <h2 id="seed" class="text-white">Know your NFTs</h2>
      <p class="text-white px-8 mt-4 mb-4 lg:mb-0 MaaxMedium">Barefoot Parrots Island gives you options. Learn about the different NFTs and utilities for each.</p>
      <button class="bg-black bg-opacity-20 lg:bg-opacity-0 rounded-full mt-4 mb-6" style="border: 2px solid #fff"><a href="/tokenomics" class="text-base lg:text-xl block px-8 py-2 text-white hover:text-white">LEARN MORE</a></button>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

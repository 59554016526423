import Bugsnag from '@bugsnag/js';
import { reactive, toRefs } from 'vue';
import Web3 from '~web3/dist/web3.min.js';
import EventBus from './eventBus.js';

let web3 = null;
let windowEth = null;
let events = new EventBus();

const state = reactive({
    initialized: false,
    present: null,
    connected: false,
    isConnecting: false,
    chainID: null,
    accounts: null,
    currentAccount: null,
});

export default function useMetamask() {
    /**
     * detect metamask and initialize web3 if metamask is present
     */
    const init = (ethereum) => {
        if (undefined !== ethereum) {
            windowEth = ethereum;

            web3 = new Web3(windowEth, {
                transactionConfirmationBlocks: 2,
            });

            state.initialized = true;
            state.present = true;

            attachListeners(windowEth);
            checkConnected().then((connected) => {
                if (connected) {
                    events.emit('connected', {
                        accounts: state.accounts,
                        currentAccount: state.currentAccount,
                        chainID: state.chainID,
                    });
                }
            });
        } else {
            let err = new Error('window.ethereum unavailable');
            throw err;
        }
    };

    const checkConnected = async () => {
        let accounts = await web3.eth.getAccounts();

        if (accounts.length === 0) {
            // user has not logged in to metamask, or connected the currently selected address to this domain
            state.connected = false;
            state.currentAccount = null;
            state.accounts = null;
        } else {
            state.connected = true;
            state.accounts = accounts;
            state.currentAccount = accounts[0];
            state.chainID = await getCurrentChain();
        }

        return state.connected;
    };

    /**
     * attach event listeners to the metamask object to detect users interacting with mm
     */
    const attachListeners = (ethereum) => {
        ethereum.on('chainChanged', chainChanged);
        ethereum.on('accountsChanged', accountChanged);
        ethereum.on('disconnect', disconnected);
    };

    /**
     * event listener to handle when a user changes their selected rpc in metamask
     */
    const chainChanged = (chainID) => {
        state.chainID = chainID;
        events.emit('chainChanged', chainID);
    };

    const getCurrentChain = async () => {
        const chainID = await ethereum.request({ method: 'eth_chainId' });
        return web3.utils.hexToNumber(chainID);
    };

    const requestChain = async (chainID) => {
        const hexChainID = web3.utils.numberToHex(chainID);
        const currentID = await getCurrentChain();

        if (chainID == currentID) {
            return true;
        }

        return windowEth
            .request({
                method: 'wallet_switchEthereumChain',
                params: [
                    {
                        chainId: hexChainID,
                    },
                ],
            })
            .then(
                () => {
                    // success!
                    return true;
                },
                (err) => {
                    // no bueno
                    console.log(err, 'from reject cb');
                    if (err.code == 4902) {
                        // this chain is not available from the current MM connection RPC list
                        // request to add it
                        // await windowEth.request({ method: wallet_addEthereumChain, params: [{ chainId: 0x000, rpcUrl: https://... }] });
                        return new Error('Chain is not available');
                    } else if (err.code == -32002) {
                        return new Error('Chain request already active');
                    }

                    return new Error('User declined switch');
                }
            )
            .catch((err) => {
                Bugsnag.notify(err);
                return false;
            });
    };

    /**
     * event listener to handle when the user changes their selected account in metamask
     */
    const accountChanged = (accounts) => {
        if (accounts.length === 0) {
            // user disconnected metamask
            disconnected();
            return;
        }

        if (accounts[0] != state.currentAccount) {
            state.currentAccount = accounts[0];
            events.emit('accountChanged', state.currentAccount);
        }
    };

    /**
     * Event listener to handle when the user disconnects metamask
     */
    const disconnected = () => {
        state.connected = false;
        state.accounts = null;
        state.currentAccount = null;

        events.emit('disconnected');
    };

    const connectWallet = async () => {
        state.isConnecting = true;

        let accounts = windowEth
            .request({ method: 'eth_requestAccounts' })
            .then(
                (accounts) => {
                    state.isConnecting = false;
                    checkConnected();
                    events.emit('connected', {
                        accounts: state.accounts,
                        currentAccount: state.currentAccount,
                        chainID: state.chainID,
                    });
                },
                (err) => {
                    if (err.code === 4001) {
                        // eip-1193 userRejectedRequest
                        events.emit('error:connect.rejected', err);
                        return;
                    }
                    events.emit('error:connect', err);
                }
            );
    };

    const getBalance = async (account) => {
        return await web3.eth.getBalance(account);
    };

    const sign = async (message) => {
        return await web3.eth.personal
            .sign(web3.utils.utf8ToHex(message), state.currentAccount)
            .catch((err) => {
                events.emit('error:sign', err);
                return null;
            });
    };

    const loadContract = (abi, address) => {
        return new web3.eth.Contract(abi, address);
    };

    const estimateGas = async (txn, opts) => {
        return await txn.estimateGas({
            gas: opts.gas || '5000000',
            from: opts.from || state.currentAccount,
            value: String(opts.value) || '0',
        });
    };

    return {
        ...toRefs(state),
        web3,
        init,
        getCurrentChain,
        requestChain,
        on: (evt, fn) => events.on(evt, fn),
        off: (evt, fn) => events.off(evt, fn),
        once: (evt, fn) => events.once(evt, fn),
        sign,
        loadContract,
        estimateGas,
        connectWallet,
        getBalance,
    };
}

<template>
  <div class="flex-shrink-0 w-full z-0 relative flex overflow-hidden p-6">
    <div class="max-w-screen-lg mx-auto lg:grid grid-cols-2">
      <div class="relative max-w-screen-lg mx-auto text-left p-6 z-10">
        <h2 class="text-brown-900 my-6">FEATHER.x</h2>
        <p class="text-brown-900 MaaxMedium">Parrot staking to earn our ERC-20 token is coming and the FEATHER.x Exchange will be where you unlock its utility.</p>
        <button class="bg-white bg-opacity-80 lg:bg-opacity-0 rounded-full mt-4" style="border: 2px solid #4C2115"><a href="/feather" class="text-base lg:text-xl block px-8 py-2 text-brown-900 hover:text-brown-900">LEARN ABOUT $FEATHER</a></button>
      </div>
      <img src="/img/parrots/homepage/crypto.png" alt="crypto" class="my-6 mx-auto w-64 lg:w-auto">
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<template>
<div class="relative flex flex-col min-h-screen w-full bg-[url('/img/parrots/clouds.png')] bg-cover bg-no-repeat bg-center min-h-screen">
	<slot name="bgImg">
		<div class="w-full h-full hidden 2xl:block justify-self-stretch absolute z-0 bg-[url('/img/parrots/homepage/volcano.png')] bg-left-bottom bg-no-repeat bg-contain"></div>
		<div class="opacity-85 w-full h-full hidden lg:block 2xl:hidden justify-self-stretch absolute z-0 bg-[url('/img/parrots/homepage/volcano.png')] bg-left-bottom bg-no-repeat" style="background-size: 500px;"></div>
		<div class="w-full h-full lg:hidden absolute z-0 bg-[url('/img/parrots/homepage/volcano.png')] bg-left-bottom bg-no-repeat bg-contain"></div>
	</slot>

	<div class="relative z-10 w-full flex justify-between items-center py-4 px-8 m-0">
		<div class="md:flex-none p-4">
			<a href="/" class="logo"><img src="/img/parrots/logo.png" class="inline" style="max-height: 100px"></a>
		</div>
		<ul class="menu flex justify-end items-center z-30">
			<li class="flex-grow flex justify-start items-center">
				<input type="checkbox" id="toggle-nav" class="invisible">
				<label for="toggle-nav"
					id="close-nav"
					class="hidden lg:hidden cursor-pointer
					fixed top-12 right-9 z-40"><i class="fas fa-times fa-2x text-white"></i></label>
				<ul id="nav-menu" class="
					flex items-center
				 	left-[150%]
					transition-[left]
					z-30
					flex-col justify-start space-y-6 fixed bottom-0 top-0 right-0 pt-32 shadow-lg text-left bg-brown-900 drop-shadow-xl
					lg:flex-row lg:justify-between lg:space-y-0 lg:relative lg:bottom-auto lg:left-auto lg:top-auto lg:right-auto lg:p-0 lg:shadow-none lg:text-center lg:bg-transparent lg:drop-shadow-none">
					<li><a href="/" class="text-4xl lg:text-xl block lg:hidden px-8 py-2 text-brown-400 hover:text-white lg:text-brown-800 lg:hover:text-white MaaxBold">Home</a></li>
					<li><a href="/tokenomics" class="text-4xl lg:text-xl block px-8 py-2 text-brown-400 hover:text-white lg:text-brown-800 lg:hover:text-white MaaxBold">NFTs</a></li>
					<!-- <li><a href="/feather" class="text-4xl lg:text-xl block px-8 py-2 text-brown-400 hover:text-white lg:text-brown-800 lg:hover:text-white MaaxBold">Feather</a></li> -->
					<li><a href="/the-lore" class="text-4xl lg:text-xl block px-8 py-2 text-brown-400 hover:text-white lg:text-brown-800 lg:hover:text-white MaaxBold">The Lore</a></li>
					<li class="lg:hidden">
						<a href="https://barefootparrots.com/opensea" target="_blank"
							class="
							block
							text-4xl
							mx-8
							text-red-500
							hover:text-white
							hover:border-white
							MaaxBold
						">Buy Now</a>
					</li>
					<li class="lg:hidden flex-grow flex flex-col justify-end">
						<div class="flex justify-between px-8 my-12">
							<a href="/barefootparrots" target="_blank">
								<img src="/img/parrots/logo-d.svg" alt="Discord" class="mx-auto w-14 lg:w-32">
							</a>
							<a href="/opensea" target="_blank" class="hover:text-white hover:bg-neon-300">
								<img src="/img/parrots/logo-os.svg" alt="OpenSea" class="mx-auto w-14 lg:w-32">
							</a>
							<a href="/looksrare" target="_blank" class="hover:text-white">
								<img src="/img/parrots/logo-lr.svg" alt="Looks Rare" class="mx-auto w-14 lg:w-32">
							</a>
						</div>
					</li>
				</ul>
			</li>

			<li class="flex-shrink-0">
				<a href="https://barefootparrots.com/opensea" target="_blank"
					class="
					cursor-pointer
					leading-loose
					hidden
					sm:inline-block
					text-xl
					px-8
					py-2
					text-red-700
					border-2
					border-red-700
					rounded-full
					hover:text-white
					hover:border-white
					MaaxBold
				">Buy Now</a>
			</li>
			<li class="lg:hidden">
				<label for="toggle-nav"
					class="block ml-6 text-white relative cursor-pointer">
					<i class="fas fa-bars fa-2x"></i>
				</label>
			</li>
		</ul>
	</div>

	<div class="relative flex justify-center flex-grow flex-shrink-0 lg:min-h-[500px]">
		<div class="
		w-full
		relative
		p-10
		mt-6
		mx-auto

		sm:w-full

		md:px-12

		lg:ml-32
		lg:mt-28

		xl:w-3/5
		xl:mr-10

		2xl:w-1/2
		2xl:mr-36
		text-left"
		:class="textPositionClasses">

			<slot name="default" />

		</div>
	</div>
  </div>

</template>
<script>
import { computed } from 'vue';

export default {
	props: ['textPosition'],
	setup(props) {
		const textPositionClasses = computed(() => {
			if (props.textPosition) {
				return props.textPosition.split(' ');
			}

			return ['lg:float-right'];
		});

		return {
			textPositionClasses,
		}
	}
}
</script>

<template>

	<div class="text-center my-24 xs:my-44 sm:my-52 ">
		<div class="p-8 rounded-lg sm:bg-brown-400 mx-auto sm:max-w-lg relative">
			<img src="/flying-parrot.png" class="absolute left-0 top-0 -ml-24 -mt-32 xs:-ml-32 xs:-mt-48 sm:-ml-52 sm:-mt-52">
			<h1>Migration in Progress</h1>
			<div class="my-8">
				<p class="text-2xl">
					For more details, <a href="https://barefootparrots.substack.com/p/the-barefoot-parrots-migration" class="text-brown-900">click here</a>.
				</p>
				<p class="text-2xl">
					Curious about Barefoot Parrots?
					<br>
					<a href="/BarefootParrotsLore.pdf" class="text-brown-900">Read the lore</a>.
				</p>
			</div>
		</div>
	</div>

</template>


<script>
import GeneralHeader from '../../components/parrots/GeneralHeader.vue';
import Section2 from "../../components/parrots/homepage/section2.vue";
import Section3 from "../../components/parrots/homepage/section3.vue";
import Section4 from "../../components/parrots/homepage/section4.vue";
import Section5 from "../../components/parrots/homepage/section5.vue";
import GeneralFooter from '../../components/parrots/GeneralFooter.vue';

export default {
  components: {
    GeneralHeader,
	GeneralFooter,
    Section5,
    Section4,
    Section3,
    Section2,
  },
}
</script>

export default [
	{
	  "inputs": [],
	  "name": "AlreadyMinted",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MerkleProofInvalid",
	  "type": "error"
	},
	{
	  "inputs": [],
	  "name": "MintingDisabled",
	  "type": "error"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "previousOwner",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "OwnershipTransferred",
	  "type": "event"
	},
	{
	  "anonymous": false,
	  "inputs": [
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "from",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "address",
		  "name": "to",
		  "type": "address"
		},
		{
		  "indexed": true,
		  "internalType": "uint256",
		  "name": "tokenId",
		  "type": "uint256"
		}
	  ],
	  "name": "Transfer",
	  "type": "event"
	},
	{
	  "inputs": [],
	  "name": "owner",
	  "outputs": [
		{
		  "internalType": "address",
		  "name": "",
		  "type": "address"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [],
	  "name": "renounceOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "newOwner",
		  "type": "address"
		}
	  ],
	  "name": "transferOwnership",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "key",
		  "type": "bytes32"
		},
		{
		  "internalType": "address",
		  "name": "contractAddress",
		  "type": "address"
		}
	  ],
	  "name": "setMintingContract",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "root",
		  "type": "bytes32"
		}
	  ],
	  "name": "setMerkleRoot",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [],
	  "name": "getMerkleRoot",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bool",
		  "name": "active",
		  "type": "bool"
		}
	  ],
	  "name": "toggleActive",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "leaf",
		  "type": "bytes32"
		}
	  ],
	  "name": "minted",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "daoCount",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "eventCount",
		  "type": "uint256"
		},
		{
		  "internalType": "bytes32[]",
		  "name": "proof",
		  "type": "bytes32[]"
		}
	  ],
	  "name": "mintTokens",
	  "outputs": [],
	  "stateMutability": "nonpayable",
	  "type": "function"
	},
	{
	  "inputs": [
		{
		  "internalType": "address",
		  "name": "sender",
		  "type": "address"
		},
		{
		  "internalType": "uint256",
		  "name": "daoCount",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "eventCount",
		  "type": "uint256"
		}
	  ],
	  "name": "packLeaf",
	  "outputs": [
		{
		  "internalType": "bytes",
		  "name": "",
		  "type": "bytes"
		}
	  ],
	  "stateMutability": "pure",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "uint256",
		  "name": "daoCount",
		  "type": "uint256"
		},
		{
		  "internalType": "uint256",
		  "name": "eventCount",
		  "type": "uint256"
		}
	  ],
	  "name": "packLeaf",
	  "outputs": [
		{
		  "internalType": "bytes",
		  "name": "",
		  "type": "bytes"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes",
		  "name": "_leafData",
		  "type": "bytes"
		}
	  ],
	  "name": "hashLeaf",
	  "outputs": [
		{
		  "internalType": "bytes32",
		  "name": "hashed",
		  "type": "bytes32"
		}
	  ],
	  "stateMutability": "pure",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_leaf",
		  "type": "bytes32"
		},
		{
		  "internalType": "bytes32[]",
		  "name": "_proof",
		  "type": "bytes32[]"
		},
		{
		  "internalType": "bytes32",
		  "name": "_root",
		  "type": "bytes32"
		}
	  ],
	  "name": "verify",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "pure",
	  "type": "function",
	  "constant": true
	},
	{
	  "inputs": [
		{
		  "internalType": "bytes32",
		  "name": "_leaf",
		  "type": "bytes32"
		},
		{
		  "internalType": "bytes32[]",
		  "name": "_proof",
		  "type": "bytes32[]"
		}
	  ],
	  "name": "verify",
	  "outputs": [
		{
		  "internalType": "bool",
		  "name": "",
		  "type": "bool"
		}
	  ],
	  "stateMutability": "view",
	  "type": "function",
	  "constant": true
	}
  ]

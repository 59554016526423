<template>
	<div class="bg-brown-900 text-brown-400 rounded-xl">
		<div class="w-3/4 mx-auto">
			<h3 class="text-brown-400">
				Your Tokens Have Been Migrated!
			</h3>
		</div>

		<div class="sm:grid sm:grid-cols-2 sm:gap-4 my-8 text-xl">
			<div>
				<div class="text-brown-400">DAO Tokens Minted</div>
				<div class="text-3xl font-bold my-2">{{ daoCount }}</div>
			</div>

			<div>
				<div class="text-brown-400">Event Passes Minted</div>
				<div class="text-3xl font-bold my-2">{{ eventPassCount }}</div>
			</div>
		</div>

		<div class="mt-4">
			<button class="
				bg-brown-400
				text-brown-800
				text-xl
				rounded-lg
				block
				w-full
				text-2xl
				py-4
				cursor-pointer
				uppercase
				group
				disabled:bg-brown-400/50
				disabled:hover:bg-brown-400/50
				disabled:border-brown-600/50"
				@click="props.close">
				<span
					class="text-brown-800 group-disabled:text-brown-600/50">
					Close
				</span>
			</button>
		</div>
	</div>
</template>
<script>
import { computed } from 'vue';
const DAO_TOKEN_ADDRESS = import.meta.env.VITE_DAO_TOKEN_ADDRESS;
const EVENTPASS_TOKEN_ADDRESS = import.meta.env.VITE_EVENTPASS_TOKEN_ADDRESS;
export default {
	props: ['data', 'close'],
	setup(props) {
		const daoCount = computed(() => {
			return daoTokens.value.length;
		});

		const eventPassCount = computed(() => {
			return eventPassTokens.value.length;
		});

		const daoTokens = computed(() => {
			try {
				return props.data.receipt.events.Transfer.map((event) => {
					if (event.address == DAO_TOKEN_ADDRESS) {
						return event.returnValues.tokenId;
					}

					return null;
				}).filter( id => id !== null);
			} catch (e) {
				return [];
			}
		});

		const eventPassTokens = computed(() => {
			try {
				return props.data.receipt.events.Transfer.map((event) => {
					if (event.address == EVENTPASS_TOKEN_ADDRESS) {
						return event.returnValues.tokenId;
					}

					return null;
				}).filter( id => id !== null);
			} catch (e) {
				return 0;
			}
		});

		return {
			props,
			daoCount,
			daoTokens,
			eventPassCount,
			eventPassTokens,
		};
	}
}
</script>

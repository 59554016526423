function ValidationError(errors) {
    this.name = 'ValidationError';
    this.errors = errors || {};
}
ValidationError.prototype = new Error();
ValidationError.prototype.constructor = ValidationError;

function UnknownError(msg) {
    this.name = 'UnknownError';
    this.message = msg || 'An unknown error occurred';
}
UnknownError.prototype = new Error();
UnknownError.prototype.constructor = UnknownError;

export { ValidationError, UnknownError };

// const codes = {
//     VALIDATION_ERROR: 422,
//     UNKNOWN_ERROR: 500,
// };

// export { codes };
// export function make(code, msg) {
//     let err;
//     switch (code) {
//         case ErrorCodes.VALIDATION_ERROR:
//             err = new Error('The data was invalid');
//             err.prototype.handled = true;
//             err.prototype.code = code;
//             err.prototype.getErrors = () => {
//                 return msg;
//             };
//             break;

//         default:
//             err = new Error(msg);
//             err.prototype.handled = false;
//             err.prototype.code = code;
//             break;
//     }

//     console.log(err);

//     return err;
// }
// export default {
//     UNEXPECTED: 900,
//     GAS_ESTIMATION: 901,
//     ORDER_VALIDATION: 902,
//     error: (code, msg) => {
//         let error = new Error();
//         error.handled = true;
//         error.code = code;
//         error.msg = msg;

//         return error;
//     },
// };

<template>
	<div class="text-brown-800 px-8 md:px-0 max-w-screen-md">
		<h3 class="Nove text-4xl leading-relaxed my-0 py-0">
			Select your Mint Passes to mint, and your Unrevealed Parrots to reveal.
		</h3>

		<p class="my-0 py-0">Revealing an "Un-revealed Parrot" costs nothing beyond the price of gas.</p>

		<div class="flex justify-end items-center space-x-4 mt-8">
			<div class="font-semibold">
				<p class="my-0 py-0 Nove text-xl sm:text-3xl text-right">ETH Total</p>
				<p class="py-0 my-0">{{ state.price }} <i class="fab fa-ethereum text-brown-800"></i> each</p>
			</div>
			<div class="flex justify-center items-center text-2xl md:text-3xl font-light w-1/2 md:w-3/12 bg-brown-400 bg-opacity-80 px-4 py-4 rounded-lg border-4 border-brown-800 Nove">
				{{ ethTotal }}
				<i class="fab fa-ethereum text-brown-800 ml-2 text-2xl"></i>
			</div>
		</div>

		<div class="flex justify-between items-center my-2">
			<div class="flex-grow whitespace-nowrap text-left">
				<button
					class="bg-brown-400/40
					text-brown-800
					border-2
					border-brown-800
					rounded-full
					px-4 py-1
					uppercase"
					@click="selectAll">
					Select {{ state.tokens.length <= LIMIT? 'All' : `Next ${LIMIT}` }}
				</button>
			</div>

			<p class="text-brown-800 text-right text-sm Nove flex-shrink my-0 leading-none">Limit 20 tokens per transaction</p>
		</div>

		<div v-if="state.loadingTokens">
			<div class="dk-brown Nove text-4xl text-center my-12">
				<div class="loading flex justify-center items-center">
					Loading your tokens
					<i class="fas fa-spinner fa-spin text-3xl mx-2 opacity-50"></i>
				</div>
			</div>
		</div>
		<div v-else
			class="my-6 xs:grid xs:grid-cols-2 gap-4 sm:grid-cols-3 sm:gap-6">
			<Token v-for="token in state.tokens" :key="`token-${token.id}`"
				class="mb-6 xs:mb-0"
				:id="token.id"
				:level="token.traits['Royal Level'] || null"
				:type="tokenType(token)"
				:selected="state.selectedTokenIDs.indexOf(token.id) >= 0"
				@click="toggleSelected(token.id)"
			/>
		</div>

		<div class="my-12">
			<button class="bg-brown-400
					bg-opacity-50
					rounded-full
					border-2
					border-brown-800
					text-center
					block
					w-full
					text-3xl
					py-4
					px-6
					uppercase
					group
					disabled:hover:bg-brown-400/50
					disabled:border-brown-600/50
					"
				:disabled="state.selectedTokenIDs.length == 0"
				@click="buy">
				<span class="text-brown-800 group-disabled:text-brown-600/50">Buy Now</span>
			</button>
		</div>

		<Modal
			v-if=" !! state.showModal"
			:modalComponent="state.showModal"
			:data="modalData"
			@closeModal="hideModal"
		/>
	</div>
</template>
<script>
// libraries/resources
import { computed, markRaw, onMounted, reactive, ref, unref } from 'vue';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import web3 from '~web3/dist/web3.min.js';
import ParrotMintABI from '../../abi/ParrotMintABI.js';
import { settings, initOrder, initContract, updateOrder, initMint, initReveal, sendTransaction, verifyOrder } from '../../modules/mint-pass.js';

// vue components
import Token from './Token.vue';
import Modal from '../Modal.vue';
import ErrorModal from './modals/ErrorModal.vue';
import UnknownErrorModal from './modals/UnknownErrorModal.vue';
import ProcessingModal from './modals/ProcessingModal.vue';
import MintedModal from './modals/MintedModal.vue';
import Bugsnag from '@bugsnag/js';

// api modules and junk
import useOpensea from '../../modules/opensea.js';
import useMetamask from '../../modules/metamask.js';
import loadUserAssets from '../../modules/user-assets.js';
import { UnknownError, ValidationError } from '../../modules/errors.js';
import sleep from '../../modules/sleep.js';

// init apis
const { init, currentAccount, sign } = useMetamask();
const { tokensForProject } = loadUserAssets();
const { init: initOpensea } = useOpensea();

// constants
const APIKey = import.meta.env.VITE_OPENSEA_APIKEY;
const tokenAddress = import.meta.env.VITE_CONTRACT_ADDRESS;
const MINT_ADDRESS = import.meta.env.VITE_MINTPASS_CONTRACT_ADDRESS;
let PRICE = 0.03;
let REVEAL_LIVE = false;

const STATE_AWAITING_SIGNATURE = 'signature'
const STATE_AUTHORIZING_TX = 'authorizing';
const STATE_AWAITING_CONFIRMATION = 'confirming';
const STATE_VERIFYING = 'verifying';
const STATE_COMPLETE = 'complete';
const MODE = import.meta.env.VITE_MODE;
const LIMIT = 20;

function probeWalletAddress(live) {
	if (import.meta.env.VITE_MODE != 'production') {
		return import.meta.env.VITE_CHECK_WALLET;
	}

	return live;
}

function metadataUrl(url) {
	if (MODE != 'production') {
		return url.replace('https://api.tikifestival.io', 'http://tikifest-api.test');
	}

	return url;
}

export default {
	components: { Token, Modal },
	setup() {
		const state = reactive({
			loadingTokens: true,
			tokens: [],
			selectedTokenIDs: [],
			showModal: null,

			price: PRICE,
			order: null,
			transactionHash: null,
			receipt: null,

			processingState: null,
		});

		const processingState = computed(() => {
			return state.processingState;
		});

		const ethTotal = computed(() => {
			const tokens = state.tokens.filter( token => state.selectedTokenIDs.indexOf(token.id) >= 0 );
			let count = tokens.reduce((cnt, token) => {
				return cnt + (tokenType(token) == 'Unrevealed Parrot'? 0 : 1);
			}, 0);

			return Math.round((count * state.price) * 1000) / 1000;
		});

		const modalData = ref(null);

		const processingModalData = computed(() => {
			return {
				processingState,
				order: state.order,
				transactionHash: state.transactionHash,
				receipt: state.receipt,
				tokens: state.tokens.filter((token) => state.selectedTokenIDs.indexOf(token.id) >= 0),
				txStates: {
					STATE_AWAITING_SIGNATURE,
					STATE_AUTHORIZING_TX,
					STATE_AWAITING_CONFIRMATION,
					STATE_VERIFYING,
					STATE_COMPLETE,
				},
			};
		});

		onMounted(async() => {
			const mintSettings = await settings();
			PRICE = Math.round( mintSettings.price * 100 ) / 100;
			state.price = PRICE;
			REVEAL_LIVE = mintSettings.reveal;

			if (undefined !== window.ethereum) {
				init(window.ethereum);
				initOpensea(APIKey);
				initContract(ParrotMintABI, MINT_ADDRESS);

				loadTokens();
			}
		});

		const loadTokens = async() => {
			state.tokens = [];
			state.selectedTokenIDs = [];
			state.loadingTokens = true;
			const minSleep = sleep(2);
			tokensForProject(tokenAddress, probeWalletAddress(currentAccount.value))
				.then(parseTokens)
				.then(() => {
					minSleep.then(() => state.loadingTokens = false);
				});
		}

		const selectAll = () => {
			state.selectedTokenIDs = [];
			state.tokens.slice(0, Math.min(state.tokens.length, LIMIT)).map(token => toggleSelected(token.id));
		}

		const toggleSelected = (tokenID) => {
			const idx = state.selectedTokenIDs.indexOf(tokenID);

			if (idx >= 0) {
				state.selectedTokenIDs.splice(idx, 1);
			} else {
				if (state.selectedTokenIDs.length < LIMIT) {
					state.selectedTokenIDs.push(tokenID);
				}
			}
		}

		const parseTokens = async(tokenObject) => {
			for (const token of Object.values(tokenObject)) {
				const updatedMetadata = await loadUpdatedMetadata(
					metadataUrl(token.token_metadata)
				);
				const tmpToken = {
					id: token.token_id,
					name: token.name,
					img: {
						original: token.image_original_url,
						preview: token.image_preview_url,
						thumb: token.image_thumbnail_url,
						default: token.image_url,
					},
					permalink: token.permalink,
					external_link: token.external_link,
					metadata_url: token.token_metadata,
					traits: parseTraits(updatedMetadata.properties),
				};

				if (tokenIsEligible(tmpToken)) {
					state.tokens.push(tmpToken);
				}
			}
		}

		const parseTraits = (traitArray) => {
			let tokenTraits = {};
			for (const trait of traitArray) {
				tokenTraits[ trait.trait_type ] = trait.value;
			}

			return tokenTraits;
		}

		const tokenIsEligible = (token) => {
			if ( undefined != token.traits['Royal Level']) {
				return token.traits['Royal Level'] == 'Level 1';
			}

			return (
				(['Mint Pass (DAO)', 'Mint Pass (Core)'].indexOf(token.traits['Parrot Type']) >= 0)
				|| ( REVEAL_LIVE && ['Unrevealed Parrot'].indexOf(token.traits['Parrot Type']) >= 0)
			);
		}

		const loadUpdatedMetadata = async(url) => {
			const response = await axios.get(url);

			if (response.status == 200) {
				return response.data;
			}

			return {};
		}

		function generateNonce() {
			return web3.utils.keccak256(currentAccount.value + ":" + uuidv4());
		}

		function getSortedTokenIDs() {
			let tokens = state.selectedTokenIDs.slice(0);
			tokens.sort((left, right) => {
				if (parseInt(left) < parseInt(right)) {
					return -1;
				}

				return 1;
			});

			return tokens;
		}

		function generateSigningMessage(tokens, nonce) {
			return tokens.join(':') + ':' + nonce;
		}

		function getOrderOperation() {
			if (REVEAL_LIVE) {
				return initReveal;
			}

			return initMint;
		}

		const buy = async() => {
			const nonce = generateNonce();
			const sortedTokens = getSortedTokenIDs();
			const msg = generateSigningMessage(sortedTokens, nonce);

			showProcessingModal();
			state.processingState = STATE_AWAITING_SIGNATURE;

			let signature;
			try {
				signature = await sign(web3.utils.keccak256(msg));
			} catch (e) {
				// console.log('signature exception', e, e.code);
				if (e.code && e.code == 4001) {
					hideModal();
					return false;
				}


				showError(e);
				return false;
			}

			if ( null == signature) {
				hideModal();
				return false;
			}

			state.processingState = STATE_AUTHORIZING_TX;

			let order;
			try {
				order = await initOrder(
					nonce,
					sortedTokens,
					signature,
					currentAccount.value,
				);
			} catch (e) {
				if (e.code && e.code == 4001) {
					hideModal();
					return false;
				}


				showError(e);
				return false;
			}

			state.order = order;
			state.processingState = STATE_AWAITING_SIGNATURE;

			let tx, gas, method = getOrderOperation();
			try {
				tx = await method(
					sortedTokens,
					order.nonce,
					order.signature
				);

				gas = await tx.estimateGas({
					from: currentAccount.value,
					value: order.price,
					gas: 75_000,
				});
			} catch(e) {
				if (e.code && e.code == 4001) {
					hideModal();
					return true;
				}

				showError(e);
				return false;
			}

			try {
				sendTransaction(tx, {
					from: currentAccount.value,
					value: order.price,
					gas: Math.ceil(gas * 1.5)
				}).
					on('transactionHash', (hash) => {
						state.processingState = STATE_AWAITING_CONFIRMATION;
						state.transactionHash = hash;

						// console.log('got tx hash', state.transactionHash);

						updateOrder(
							order.uuid,
							hash,
						);
					})
					.on('receipt', checkOrder)
					.catch((e) => {
						if (e.code && e.code == 4001) {
							hideModal();
							return true;
						}

						showError(e);

						return false;
					});
			} catch (e) {
				// console.log('wrapped error', e)

				if (e.code && e.code == 4001) {
					hideModal();
					return true;
				}

				showError(e);
				return false;
			}
		}

		async function checkOrder(receipt) {
			state.receipt = receipt;
			state.processingState = STATE_VERIFYING;

			const verified = await verifyOrder(
				state.order.uuid
			);

			if (verified) {
				state.processingState = STATE_COMPLETE;
				let tokens = state.tokens.filter((token) => state.selectedTokenIDs.indexOf(token.id) >= 0);
				loadTokens().then(async() => REVEAL_LIVE && await showMintedModal(tokens));
				return;
			}

			setTimeout(() => {
				checkOrder(receipt);
			}, 3000);
		}

		function showError(err) {
			if (MODE != 'production') {
				console.error(err, err.data);
			} else {
				Bugsnag.notify(err);
			}

			if (err instanceof ValidationError) {
				showModal(ErrorModal, {
					errors: JSON.parse(JSON.stringify(err.errors))
				});
				return;
			}

			showModal(UnknownErrorModal, {
				message: err.message,
				error: err,
			});
		}

		function showProcessingModal() {
			showModal(ProcessingModal, unref(processingModalData))
		}

		async function showMintedModal(tokens) {
			let metadata = [];
			for (let token of tokens) {
				metadata.push( await loadUpdatedMetadata(
					metadataUrl(token.metadata_url)
				));
			}

			showModal(MintedModal, {
				tokens: metadata,
			});
		}

		function showModal(modal, data) {
			state.showModal = markRaw(modal);
			modalData.value = data;
		}

		function hideModal() {
			state.showModal = null;
			modalData.value = {};
		}

		const tokenType = (token) => {
			let typeVal = 'Core';
			if (token.traits['Parrot Type'] !== undefined) {
				typeVal = token.traits['Parrot Type'];
			} else {
				typeVal = token.traits['TiKi Type'];
			}

			if (typeVal.toUpperCase().indexOf('DAO') >= 0) {
				return 'DAO Mint Pass';
			} else if (typeVal.toUpperCase().indexOf('CORE') >= 0 || typeVal.toUpperCase().indexOf('ADMIT ONE') >= 0) {
				return 'Core Mint Pass';
			}

			return typeVal;
		}

		return {
			state,
			buy,
			toggleSelected,
			ethTotal,
			hideModal,
			modalData,
			tokenType,
			LIMIT,
			selectAll,
		}
	},
}
</script>

<template>
  <div class="flex-shrink-0 w-full z-0 relative flex overflow-hidden pb-32" id="explore">
    <div class="w-full h-full hidden xl:block justify-self-stretch absolute z-0" style="background: url('/img/parrots/homepage/totum-left.png') 1% 100% no-repeat, url('/img/parrots/homepage/totum-right.png') 99% 100% no-repeat;"></div>
    <div class="relative max-w-screen-lg mx-auto text-center p-6 z-10 space-y-6">
      <h4 class="text-brown-900 my-6">Barefoot Parrots Island is a 12,555 NFT collection.</h4>
      <p class="text-brown-900 MaaxMedium">We are one of the few Web3 enabled businesses building IRL products and services (that people far beyond our NFT holders will want to enjoy).</p>
      <img src="/img/parrots/homepage/festival.png" alt="festival" class="lg:px-10 mt-6 mb-20 mx-auto">

      <div class="lg:grid grid-cols-3 gap-4 space-y-6 lg:space-y-0">
        <div class="space-y-4">
          <h3 class="bg-brown-400 text-brown-600 border-2 border-brown-800">Experience</h3>
          <p class="text-brown-900 text-left">Live music and bar venue takeovers around the country will promote the Barefoot Parrots lifestyle to the masses with a large scale music festival as our proverbial “North Star.”</p>
        </div>
        <div class="space-y-4">
          <h3 class="bg-brown-400 text-brown-600 border-2 border-brown-800">Durable Goods</h3>
          <p class="text-brown-900 text-left">Get ready to express yourself and your love for the barefoot lifestyle. Barefoot Parrots Apparel is the perfect fit for those who love good music, good drinks finished with a gentle salt air breeze.</p>
        </div>
        <div class="space-y-4">
          <h3 class="bg-brown-400 text-brown-600 border-2 border-brown-800">Consumable goods</h3>
          <p class="text-brown-900 text-left">Good times often become great around good friends and libations.  We will be bringing our own spirits brand to the party.  The first to market will be Barefoot Parrots Island Rum.</p>
        </div>
      </div>

      <p class="text-brown-900 MaaxBold">Is our flock right for you?<br />Join us on Discord to find out more!</p>
      <button class="bg-white bg-opacity-80 lg:bg-opacity-0 rounded-full mt-4" style="border: 2px solid #4C2115"><a href="https://discord.gg/barefootparrots" target="_blank" class="text-base lg:text-xl block px-8 py-2 text-brown-900 hover:text-brown-900 MaaxBold">JOIN US!</a></button>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<template>
  <div class="w-full relative flex flex-grow" style="background: url('/img/bg-dark-brown.jpg') center center repeat;">
    <div class="w-full h-full hidden lg:block justify-self-stretch absolute z-0" style="background: url('/img/tiki-art-3.png') 1% 5% no-repeat, url('/img/tiki-art-4.png') 99% 110% no-repeat;"></div>
    <div class="max-w-screen-lg mx-auto text-center p-6 relative z-10">
      <h3 class="text-4xl">Congrats on claiming your TiKiDAO(s)</h3>
      <img src="/img/tiki-hr.png" alt="" class="my-6 mx-auto">
      <!-- <p class="my-6 text-xl">
        All TikiDAOs will be updated at once after the conversion window
      </p> -->

      <SmsInput
        @submit="submitPhone"
        @confirm="confirmCode"
        @retry="() => state.codeSent = false"
        :code-sent="state.codeSent"
     />

    </div>
  </div>
</template>
<script>
import { useRouter, useRoute } from 'vue-router';
import SmsInput from './SmsInput.vue';
import { reactive, onMounted, unref } from 'vue';
import sleep from '../../modules/sleep.js';
import axios from 'axios';
import useMetamask from '../../modules/metamask.js';
const { init: initMetamask, connected, currentAccount } = useMetamask();

const API_ROOT = import.meta.env.VITE_API_ENDPOINT;//="http://tikifest-api.test/api";

export default {
  components: { SmsInput },
  setup() {
    const state = reactive({
      codeSent: false,
      phone: null,
    });

    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
      initMetamask(window.ethereum);

      if ( ! connected) {
        router.push({ path: "/tiki-dao" });
      }

      if (state.codeSent && ! state.phone) {
        state.codeSent = false;
      }
    })

    const submitPhone = async(phone, cb) => {
      let wait = sleep(1);

      state.phone = phone;
      const response = await axios.post(apiEndpoint('/sms'), {
        phone,
        wallet: unref(currentAccount),
      });

      return wait.then(() => {
        if (response.status === 200) {
          if (response.data.confirmed) {
            return router.push({ path: "/thank-you" });
          }

          if (response.data.data && response.data.data.codeSent) {
            state.codeSent = true;
          }
        }

        cb(response);
      });
    }

    const confirmCode = async(code, cb) => {
      let wait = sleep(1);

      const response = await axios.post(apiEndpoint('/sms/confirm'), {
        code,
        phone: state.phone,
        wallet: unref(currentAccount),
      });

      return wait.then(() => {
        if (response.status === 200) {
          if (response.data.confirmed) {
            router.push({ path: "/thank-you" });
            return;
          }
        }

        // confirm is over, as long as it succeeded.
        cb(response);
      });
    }

    const apiEndpoint = (absolute) => {
      return `${API_ROOT}${absolute}`;
    }

    return {
      state,
      submitPhone,
      confirmCode,
    }
  }
}
</script>

<template>
	<div class="py-16" id="mint">
		<div class="text-center my-24 xs:my-44 sm:my-52 ">
			<div class="p-8 rounded-lg sm:bg-brown-400 mx-auto sm:max-w-lg relative">
				<img src="/flying-parrot.png" class="absolute left-0 top-0 -ml-24 -mt-32 xs:-ml-32 xs:-mt-48 sm:-ml-52 sm:-mt-52">
				<h1>Migrate Your Tokens</h1>
				<div class="mt-8 space-y-8">
					<WalletConnected>
						<template #default="{ buttonEvents: { click }, walletAvailable }">
							<div>
								<p class="text-2xl">
									Connect your wallet to mint your eligible Barefoot Passes and Barefoot Pandemonium tokens now.
								</p>

								<button
										v-if="walletAvailable"
										type="button"
										class="
										Nove
										text-lg
										sm:text-2xl
										block
										w-full
										px-8
										py-6
										rounded-full
										border-brown-800
										border-2
										bg-brown-400
										bg-opacity-50
										uppercase"
										@click="click"
									>
									<!-- <span class="block font-semibold leading-none">Pay with ETH <i class="fab fa-ethereum"></i> </span> -->
									<span class="text-brown-800 block leading-none">Connect your Wallet</span>
								</button>

								<div v-else>
									<p class="text-brown-800 block leading-none uppercase Nove text-2xl my-0">
										Please use a web3 enabled browser
									</p>
									<p class="text-brown-800 my-0">
										We were unable to detect your web3 wallet in the current browser.
									</p>
								</div>

	   						<div class="bg-brown-900/90 rounded-lg text-brown-400 py-4 my-6 mb-0">
								<p class="text-xl text-brown-400 m-0 p-0">
									For more details, <a href="https://barefootparrots.substack.com/p/the-barefoot-parrots-migration" class="text-brown-400">click here</a>.
								</p>
								<p class="text-xl text-brown-400 m-0 p-0">
									Curious about Barefoot Parrots?
									<br>
									<a href="/BarefootParrotsLore.pdf" class="text-brown-400">Read the lore</a>.
								</p>
							</div>
							</div>
						</template>
						<template #connected>
							<MigrationMint />
						</template>
					</WalletConnected>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { onMounted, reactive } from 'vue';
import useMetamask from '../modules/metamask.js';
const { init: initMetamask, connected, isConnecting, connectWallet, currentAccount, loadContract, on, sign, requestChain } = useMetamask();

const CHAIN_ID = import.meta.env.VITE_NETWORK_ID;
const MODE = import.meta.env.VITE_MODE;
const CHECK_WALLET = import.meta.env.VITE_CHECK_WALLET;

import GeneralHeader from '../components/parrots/GeneralHeader.vue';
import GeneralFooter from '../components/parrots/GeneralFooter.vue';
import WalletConnected from '../components/WalletConnected.vue';
import MigrationMint from '../components/migrate/Migrate.vue';

export default {
	components: { GeneralHeader, GeneralFooter, WalletConnected, MigrationMint },
	emits: ['next'],
	setup(props, { emit: $emit }) {
		const state = reactive({
			error: null,
			isConnected: connected,
			isConnecting,
			chainID: null,
		});

		onMounted(() => {
			attachEvents();

			try {
				initMetamask(window.ethereum);
			} catch (err) {
			}
		});

		const attachEvents = () => {
			on('connected', onConnected);

			on('chainChanged', async() => {
			});
		}

		const onConnected = async(connection) => {
            console.log('connected');
			state.chainID = connection.chainID;
			// loadUser(getUserWallet(connection.currentAccount));

			const onChain = await verifyChain(CHAIN_ID);
			if ( ! onChain) {
                alert('Please select the Ethereum Mainnet RPC in your wallet');
			    return;
			}

			$emit('next')
		}

        const getUserWallet = (wallet) => {
            if (MODE == 'test') {
                return CHECK_WALLET;
            }

            return wallet;
        }

		const verifyChain = async(chainID) => {
			const result = await requestChain(chainID);

			if ( ! result) {
				displayError("Your wallet must be set to the ethereum mainnet to continue");
			}

			return result;
		}

		const clearError = () => {
			state.error = null;
		}

		const displayError = (error) => {
			state.error = error;
		}

        const next = () => {
            $emit('next');
        }

		return {
			state,
			connectWallet,
            next,
		}
	}
}
</script>

import { ethers } from 'ethers';

import MerkleWrapper from './MerkleWrapper.js';

export default class ParrotMigration extends MerkleWrapper {
  static buildLeaf(wallet, daoCount, eventCount) {
	return ethers.utils.solidityKeccak256(
		[ 'address', 'uint256', 'uint256' ],
		[ wallet, daoCount, eventCount ]
	);
  }
}

import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Vue3Autocounter from 'vue3-autocounter';
import VueSmoothScroll from 'vue3-smooth-scroll';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import useOpensea from './modules/opensea.js';
import loadUserAssets from './modules/user-assets.js';
import availableProjects from './modules/available-projects';

const opensea = useOpensea();
opensea.init(import.meta.env.VITE_OPENSEA_APIKEY);
const userAssets = loadUserAssets();
userAssets.init(opensea, availableProjects);

if (import.meta.env.VITE_MODE == 'production') {
    Bugsnag.start({
        apiKey: 'e3244c6bb9ac09be1f8ea0bda8471c84',
        plugins: [new BugsnagPluginVue()],
    });
}

const bugsnagVue = Bugsnag.getPlugin('vue');

import App from './App.vue';
import './index.css';

import index from './pages/index.vue';
import tikiDao from './pages/tiki-dao.vue';
// import carve from './pages/carve.vue';
// import carve from './pages/carve-closed.vue';
import carveTerms from './pages/carve-terms.vue';
import sms from './pages/sms.vue';
import thankYou from './pages/thank-you.vue';
import gasRebate from './pages/gas-rebate.vue';
import tokenStatus from './pages/token-status.vue';
import info from './pages/info.vue';

import parrotHome from './pages/parrots/index.vue';
import parrotTokenomics from './pages/parrots/tokenomics.vue';
import parrotFeather from './pages/parrots/feather.vue';
import parrotLore from './pages/parrots/lore.vue';

import mint from './pages/mint.vue';
import parrots from './pages/parrots.vue';
import migrate from './pages/migrate.vue';

const routes = [
    // { path: '/tiki', component: index },
    // { path: '/claim/tiki-airdrop', component: gasRebate },
    // { path: '/token/:tokenID?', component: tokenStatus, name: 'tiki-status' },
    // { path: '/tiki-dao/vote', component: gasRebate },
    // { path: '/tiki-dao', component: tikiDao },
    // { path: '/carve/terms', component: carveTerms },
//    { path: '/parrots', component: parrots },
    // { path: '/mint', component: mint },
    // { path: '/sms', component: sms },
    // { path: '/thank-you', component: thankYou },
    // { path: '/info', component: info },
    { path: '/', component: parrotHome },
    // { path: '/tokenomics', component: parrotTokenomics },
    // { path: '/feather', component: parrotFeather },
    // { path: '/the-lore', component: parrotLore },
	{ path: '/migrate', component: migrate },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

const app = createApp(App)
    .use(router)
    .component('vue3-autocounter', Vue3Autocounter)
    .use(VueSmoothScroll);

if (import.meta.env.VITE_MODE == 'production') {
    app.use(bugsnagVue);
}

app.directive('focus', {
    mounted(el) {
        el.focus();
    },
});

app.mount('#app');

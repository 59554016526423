import axios from 'axios';
import { ValidationError, UnknownError } from './errors.js';
import useMetamask from './metamask.js';

const { loadContract, estimateGas } = useMetamask();

let contract;
export function initContract(abi, address) {
    contract = loadContract(abi, address);
}

export async function settings() {
    const response = await axios.get(apiEndpoint('/parrots')).catch((err) => {
        throw new UnknownError();
    });

    if (response.status == 200) {
        return response.data;
    }

    throw new UnknownError();
}
/**
 * Initialize Order
 *
 * 1. submit signature, nonce, and token ids
 * 2. return order details
 */
export async function initOrder(nonce, sortedTokens, signature, wallet) {
    const response = await axios
        .post(apiEndpoint('/parrots/order'), {
            token_id: sortedTokens,
            nonce,
            signature,
            wallet,
        })
        .catch((err) => {
            if (err.response.status == 422) {
                throw new ValidationError(err.response.data.errors);
            }

            throw new UnknownError();
        });

    if (response.status == 200 && response.data.success) {
        return response.data.order;
    }

    throw new UnknownError();
}

export async function initMint(tokenIDs, nonce, sig) {
    return await contract.methods.create(
        tokenIDs,
        `0x${nonce.replace('0x', '')}`,
        `0x${sig.replace('0x', '')}`
    );
}

export async function initReveal(tokenIDs, nonce, sig) {
    return await contract.methods.reveal(
        tokenIDs,
        `0x${nonce.replace('0x', '')}`,
        `0x${sig.replace('0x', '')}`
    );
}

export function sendTransaction(tx, opts) {
    return tx.send({
        ...opts,
    });
}

export async function updateOrder(uuid, txHash) {
    const response = await axios.post(apiEndpoint(`/parrots/order/${uuid}`), {
        tx_hash: txHash,
    });
}

export async function verifyOrder(uuid) {
    const response = await axios.get(apiEndpoint(`/parrots/order/${uuid}`));

    if (response.status == 200) {
        return response.data.order.tx_verified_at;
    }

    return false;
}

export function apiEndpoint(path) {
    return import.meta.env.VITE_API_ENDPOINT + path;
}
